import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

const Registration = ({tournament}) => {
    const props = useParams();
    const params = new URLSearchParams(window.location.search)

    const [ data, setData ] = useState({})
    const [ registered, setRegistered ] = useState(false)
    const [ inProgress, setInProgress ] = useState(false)

    if(tournament?.block_registration_from_telegram){
        window.location.href = '/';
    }

    useEffect(() => {
        setData({})
    }, [tournament])

    const handleMessage = async (event) => {
        event.preventDefault()
        if(inProgress){
            return
        }
        setInProgress(true)

        let values = Object.assign({}, data)
        for(const param of Object.keys(values.parameters)){
            if(!document.getElementById(`parameter-${param}`)){
                delete values.parameters[param]
            }
        }

        const payload = await axios.post(`https://api.arena.promo/api/registration?jwt=`+params.get('jwt'), values)

        if(payload?.data?.id){
            toast("Заявку успішно створено.", {type: 'success', autoClose: false})
            setRegistered(true)
        }else{
            toast("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.", {type: 'error', autoClose: false})
        }
    }

    const addNew = () => {
        setData({
            ...data,
            parameters: {},
        })
        setRegistered(false)
    }

    const isHidden = (key, param) => {
        return (param['hidden'] ?? []).includes(`${key}`)
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            {!registered && (
                                <div className="col-lg-6">
                                    <div className="contact-box sc-md-mb-5">
                                        <form onSubmit={handleMessage}>
                                            <div className="login-form">
                                                <div className="form-box">
                                                    <label className="sc-mb-10">
                                                        {tournament?.additional_settings?.telegram?.labels?.['participant-name'] ?? 'ПІБ учасника(ів) / Назва колективу'}*
                                                    </label>
                                                    <input
                                                        className="from-control"
                                                        type="text"
                                                        placeholder={tournament?.additional_settings?.telegram?.labels?.['participant-name'] ?? 'ПІБ учасника(ів) / Назва колективу'}
                                                        value={data.name}
                                                        onChange={e => setData({...data, name: e.target.value})}
                                                        required
                                                        autoComplete="off"
                                                     />
                                                </div>
                                                {!(tournament?.additional_settings?.registration || {})['skip-phone'] && (
                                                    <div className="form-box">
                                                        <label className="sc-mb-10">
                                                            {tournament?.additional_settings?.telegram?.labels?.phone ?? 'Телефон'}*
                                                        </label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            placeholder={tournament?.additional_settings?.telegram?.labels?.phone ?? 'Телефон'}
                                                            value={data.phone}
                                                            onChange={e => setData({...data, phone: e.target.value})}
                                                            required
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                )}

                                                {!(tournament?.additional_settings?.registration || {})['skip-address'] && (
                                                    <div className="form-box">
                                                        <label className="sc-mb-10">{tournament?.additional_settings?.telegram?.labels?.address ?? 'Місто'}*</label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            placeholder={tournament?.additional_settings?.telegram?.labels?.address ?? 'Місто'}
                                                            value={data.address}
                                                            onChange={e => setData({...data, address: e.target.value})}
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                )}

                                                {!(tournament?.additional_settings?.registration || {})['skip-coach'] && (
                                                    <div className="form-box">
                                                        <label className="sc-mb-10">{tournament?.additional_settings?.telegram?.labels?.coach ?? 'Тренер'}*</label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            placeholder={tournament?.additional_settings?.telegram?.labels?.coach ?? 'Тренер'}
                                                            value={data.coach}
                                                            onChange={e => setData({...data, coach: e.target.value})}
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                )}

                                                {!(tournament?.additional_settings?.registration || {})['skip-school'] && (
                                                    <div className="form-box">
                                                        <label className="sc-mb-10">{tournament?.additional_settings?.telegram?.labels?.school ?? 'Назва студії/школи'}</label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            placeholder={tournament?.additional_settings?.telegram?.labels?.school ?? 'Назва студії/школи'}
                                                            value={data.school}
                                                            onChange={e => setData({...data, school: e.target.value})}
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                )}

                                                {!(tournament?.additional_settings?.registration || {})['skip-title'] && (
                                                    <div className="form-box">
                                                        <label className="sc-mb-10">{tournament?.additional_settings?.telegram?.labels?.title ?? 'Назва номеру'}*</label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            placeholder={tournament?.additional_settings?.telegram?.labels?.title ?? 'Назва номеру'}
                                                            value={data.title}
                                                            onChange={e => setData({...data, title: e.target.value})}
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                )}

                                                {(tournament?.definition_participant_additional_parameters || [])
                                                    .filter(p => p.type === 'buttons')
                                                    .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                                                    .map(param => (
                                                    <div className="form-box" key={param.key} id={`parameter-${param.key}`}>
                                                        <label className="sc-mb-10">{param.name}</label>
                                                        <select
                                                            className="sc-select"
                                                            value={(data?.parameters || {})[param.key] || ''}
                                                            label={param.name}
                                                            required
                                                            onChange={type => setData({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                                                        >
                                                            <option value="">Оберіть значення</option>
                                                            {Object.keys(param.values).filter(k => !isHidden(k, param)).map(k => (
                                                                <option key={k} value={k}>{param.values[k]}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ))}

                                                {(tournament?.definition_participant_additional_parameters || [])
                                                    .filter(p => p.type === 'message')
                                                    .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                                                    .map(param => (
                                                    <div className="form-box" key={param.key} id={`parameter-${param.key}`}>
                                                        <label className="sc-mb-10">{param.name}</label>
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            required
                                                            placeholder={param.name}
                                                            value={(data?.parameters || {})[param.key] || ''}
                                                            onChange={type => setData({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                                                            autoComplete="off"
                                                         />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="submit-button" style={{marginTop: '15px'}}>
                                                <input className="submit-btn" type="submit" value="Зареєструвати" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                            {!!registered && (
                                <div className="col-lg-12">
                                    <h2 className="heading-title sc-mb-25 text-center primary-color">Вітаємо!</h2>
                                    <div className="description sc-mb-20 text-center">
                                        <p>
                                          {tournament?.registration_website_success_notes ?? 'Вас успішно зареєстровано. Статус реєстрації ви можете перевірити в у вашому телеграм. Також в телеграм ви можете додати музичний супровід до вашого виступу, оновити квитанцію про сплату внеску та інші дії.'}
                                        </p>

                                        <div className="submit-button" >
                                            <input className="submit-btn" type="button" value={(tournament?.additional_settings?.telegram?.labels || {})['register-another-participant'] ?? 'Додати учасника'} onClick={() => addNew()} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Registration;
