import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useParams } from 'react-router';

import instruction1_1 from '../../../assets/images/document/instruction1_1.png'
import instruction1_2 from '../../../assets/images/document/instruction1_2.png'
import instruction1_3 from '../../../assets/images/document/instruction1_3.png'

import instruction2_1 from '../../../assets/images/document/instruction2_1.png'

const Form = ({ participant }) => {
    const props = useParams();
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ progress, setProgress ] = useState(false);
    const [ successURL, setSuccessURL ] = useState(false);
    const [ errorURL, setErrorURL ] = useState(null);
    const [ progressURL, setProgressURL ] = useState(false);
    const [ googleDriveUrl, setGoogleDriveUrl ] = useState(false);
    const [ showInstruction1, setShowInstruction1 ] = useState(false);
    const [ showInstruction2, setShowInstruction2 ] = useState(false);

    const toogleInstruction1 = () => setShowInstruction1(!showInstruction1)
    const toogleInstruction2 = () => setShowInstruction2(!showInstruction2)

    const fileRef = React.createRef()

    const urlParams = new URLSearchParams(window.location.search);
    const name = decodeURI(urlParams.get('name'));

    const upload = async (document) => {
        setProgress(true)
        setError(false)

        const formData = new FormData();
        formData.append('scope', props.scope);
        formData.append('type', props.type);
        formData.append('id', props.id);
        formData.append('timestamp', props.timestamp);
        formData.append('document_type', props.document_type);
        formData.append('document', document);

        const data = await axios.post(`https://api.arena.promo/api/document/${props.scope}/${props.type}/${props.id}/${props.timestamp}/${props.document_type}`, formData, {headers: {
            'content-type': 'multipart/form-data',
        }}).catch(e => {
            setError(true)
        })

        setProgress(false)

        if(data?.data?.id){
            setSuccess(true);
            setError(false)
        }else{
            setError(true)
        }
    }

    const sendGoogleDriveURL = async () => {
        const parts = googleDriveUrl.split('/')
        if(
            parts.length != 7
            || !parts[0].includes("https:")
            || !parts[2].includes("drive.google.com")
            || !parts[3].includes("file")
            || !parts[4].includes("d")
        ){
            alert('Посилання невірне, будь-ласка, перевірте правильність посилання')
            return;
        }

        setProgressURL(true)
        setErrorURL(false)

        const formData = new FormData();
        formData.append('scope', props.scope);
        formData.append('type', props.type);
        formData.append('id', props.id);
        formData.append('timestamp', props.timestamp);
        formData.append('document_type', props.document_type);
        formData.append('url', googleDriveUrl);

        const data = await axios.post(`https://api.arena.promo/api/document/${props.scope}/${props.type}/${props.id}/${props.timestamp}/${props.document_type}`, formData, {headers: {
            'content-type': 'multipart/form-data',
        }}).catch(e => {
            setErrorURL(true)
        })

        setProgressURL(false)

        if(data?.data?.id){
            setSuccessURL(true);
            setErrorURL(false)
            setGoogleDriveUrl('')
        }else{
            setErrorURL(true)
        }
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <h4 className="heading-title sc-mb-25" style={{textAlign: 'center'}}>
                                Завантаження "{name}" для учасника {participant?.participant?.name} до турніру {participant?.tournament?.name}
                            </h4>
                        </div>
                        <div className="row">
                            <p>Будь-ласка, натисніть на кнопку "Завантажити" та оберіть файл на вашому пристрої.</p>
                            <p style={{color: 'red', fontWeight: 400}}>УВАГА: якщо розмір файлу більше ніж 100Мб - треба скористатись завантаженням через Google Drive</p>
                            <input type="file" onChange={(e) => upload(e.target.files[0])} ref={fileRef} style={{display: 'none'}} />

                            {!success && !progress && (
                                <span className="sign-in-btn" style={{width: '200px', margin: 'auto', marginBottom: '20px'}}
                                    onClick={() => fileRef.current.click()}
                                >
                                    <span>Завантажити</span>
                                </span>
                            )}

                            {!!progress && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'blue'}}>
                                    Завантажується...
                                </h4>
                            )}

                            {!!success && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'green'}}>
                                    Успішно завантажено
                                </h4>
                            )}

                            {!!error && (
                                <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'red'}}>
                                    Помилка завантаження. Спробуйте ще раз.
                                </h4>
                            )}
                        </div>

                        <div class="row mt-3">
                            <p>Або ви можете скористатись завантаженням відео за допомогою посилання на Google Drive</p>
                            <p style={{color: 'red', fontWeight: 400}}>УВАГА: максимальний розмір файлу для завантаження через Google Drive - 1.5Gb</p>
                            <p>Будь-ласка, виконайте наступні кроки:</p>
                            <ul>
                                <li>1. Завантажте відео на https://drive.google.com/ (у вас має бути аккаунт google)</li>
                                <li>
                                    2. Натисніть 'Поділитись' на сторінці відео, або через меню у переліку файлів (<span style={{color: 'blue'}} onClick={() => toogleInstruction1()}>{showInstruction1 ? 'сховати інструкцію' : 'показати інструкцію'}</span>)
                                    {!!showInstruction1 && (
                                        <div >
                                            <img src={instruction1_1} style={{width: '300px', margin: '10px'}} />
                                            <img src={instruction1_2} style={{width: '300px', margin: '10px'}} />
                                            <img src={instruction1_3} style={{width: '200px', margin: '10px'}} />
                                        </div>
                                    )}
                                </li>
                                <li>
                                    3. Надйте доступ 'Усім, хто має посилання' - 'Може переглядати' (<span style={{color: 'blue'}} onClick={() => toogleInstruction2()}>{showInstruction2 ? 'сховати інструкцію' : 'показати інструкцію'}</span>)
                                    {!!showInstruction2 && (
                                        <div >
                                            <img src={instruction2_1} style={{width: '500px', margin: '10px'}} />
                                        </div>
                                    )}
                                </li>
                                <li>4. Натисніть на кнопку 'Копіювати посилання'</li>
                                <li>5. Вставте посилання в поле нижче. Має бути текст виду 'https://drive.google.com/file/d/...file_id.../view?usp=sharing'</li>
                            </ul>

                            <div className="contact-box sc-md-mb-5">
                            <form>
                                <div className="login-form">
                                    <div className="form-box">
                                        <label className="sc-mb-10">Посилання на google drive*</label>
                                        <input className="from-control" type="text" id="url" name="url" placeholder="Посилання на google drive"
                                          onChange={e => setGoogleDriveUrl(e.target.value)} required autoComplete="off" />
                                    </div>
                                </div>
                                {!!progressURL && (
                                    <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'blue'}}>
                                        Завантажується...
                                    </h4>
                                )}

                                {!!successURL && (
                                    <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'green'}}>
                                        Успішно завантажено
                                    </h4>
                                )}

                                {!!errorURL && (
                                    <h4 className="heading-title sc-mb-25" style={{textAlign: 'center', color: 'red'}}>
                                        Помилка завантаження. Спробуйте ще раз.
                                    </h4>
                                )}

                                {!successURL && !progressURL && (
                                    <div className="submit-button">
                                        <input className="submit-btn" type="button" value="Відправити" onClick={() => sendGoogleDriveURL()} />
                                    </div>
                                )}
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form;
