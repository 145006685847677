import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../src/assets/fonts/remixicon.css';
import '../src/assets/css/style.css';

// Components
import Home from './component/Pages/Home/Home';
import About from './component/Pages/About/About';
import Tickets from './component/Pages/Tickets/Tickets';
import Document from './component/Pages/Document/Document';
import Votes from './component/Pages/Votes/Votes';
import AllVotes from './component/Pages/Votes/AllVotes';
import VotesAir from './component/Pages/VotesAir/Votes';
import Insurance from './component/Pages/Insurance/Insurance';
import EditProposal from './component/Pages/EditProposal/Edit';
import PosaFormElements from './component/Pages/PosaFormElements';
import Registration from './component/Pages/Registration';
import Contact from './component/Pages/Contact/Contact';
import Events from './component/Pages/Events/index';
import EventDetails from './component/Pages/Events/Details';
import Services from './component/Pages/Services/Services';

import MonobankOrderResult from './component/Pages/Monobank/Order/Result';


import AirGymnastics from './component/Pages/Sports/AirGymnastics';
import SportDances from './component/Pages/Sports/SportDances';
import PoleDance from './component/Pages/Sports/PoleDance';
import ModernDances from './component/Pages/Sports/ModernDances';

import FeaturesManager from './component/Pages/Features/Manager';
import FeaturesSchedule from './component/Pages/Features/Schedule';
import FeaturesJudge from './component/Pages/Features/Judge';
import FeaturesPayments from './component/Pages/Features/Payments';
import FeaturesTickets from './component/Pages/Features/Tickets';
import FeaturesReports from './component/Pages/Features/Reports';
import FeaturesDesign from './component/Pages/Features/Design';
import FeaturesTelegram from './component/Pages/Features/Telegram';
import FeaturesTelegramBusiness from './component/Pages/Features/TelegramBusiness';

import Price from './component/Pages/Price/Price';
import Team from './component/Pages/Team/Team';
import Manager from './component/Pages/Manager/List';
import TeamDetails from './component/Pages/TeamDetails/TeamDetails';
import Faq from './component/Pages/Faq/Faq';
import Login from './component/Pages/Login/Login';
import SignUp from './component/Pages/SignUp/SignUp';
import Error404 from './component/Pages/Error404/Error404';
import ScrollUpBtn from './component/Shared/ScrollUpBtn';
import ScrollToTop from './component/Shared/ScrollToTop';

function App() {
  return (
    <div>
      <Helmet>
        <title>Arena.promo - найкраща система для створення спортивних змагань.</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>

      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/ticket/:scope/:id/:timestamp' element={<Tickets />} ></Route>
        <Route path='/document/:scope/:type/:id/:timestamp/:document_type' element={<Document />} ></Route>
        <Route path='/votes/:scope/:type/:id/:timestamp' element={<Votes />} ></Route>
        <Route path='/all-votes/:scope/:tournament/:telegram/:timestamp' element={<AllVotes />} ></Route>
        <Route path='/votes-air/:scope/:type/:id/:timestamp' element={<VotesAir />} ></Route>
        <Route path='/registration' element={<Registration />} ></Route>
        <Route path='/posa-form-elements' element={<PosaFormElements />} ></Route>
        <Route path='/edit' element={<EditProposal />} ></Route>
        <Route path='/about' element={<About />} ></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/insurance' element={<Insurance />}></Route>
        <Route path='/events' element={<Events />}></Route>
        <Route path='/events/:id' element={<EventDetails />}></Route>
        <Route path='/services' element={<Services />}></Route>

        <Route path='/features/manager' element={<FeaturesManager />}></Route>
        <Route path='/features/schedule' element={<FeaturesSchedule />}></Route>
        <Route path='/features/tickets' element={<FeaturesTickets />}></Route>
        <Route path='/features/judge' element={<FeaturesJudge />}></Route>
        <Route path='/features/payments' element={<FeaturesPayments />}></Route>
        <Route path='/features/design' element={<FeaturesDesign />}></Route>
        <Route path='/features/reports' element={<FeaturesReports />}></Route>
        <Route path='/features/telegram' element={<FeaturesTelegram />}></Route>
        <Route path='/features/telegram-business' element={<FeaturesTelegramBusiness />}></Route>

        <Route path='/sports/air-gymnastics' element={<AirGymnastics />}></Route>
        <Route path='/sports/sport-dances' element={<SportDances />}></Route>
        <Route path='/sports/pole-dance' element={<PoleDance />}></Route>
        <Route path='/sports/modern-dances' element={<ModernDances />}></Route>

        <Route path='/monobank/order-result' element={<MonobankOrderResult />}></Route>

        <Route path='/price' element={<Price />}></Route>
        <Route path='/manager' element={<Manager />}></Route>
        <Route path='*' element={<Error404 />}></Route>
      </Routes>
      <ScrollToTop />
      <ScrollUpBtn />
      <ToastContainer />
    </div>
  );
}

export default App;
